import React from 'react';
import { SITE_TITLE_APPEND } from '../constants';
import Seo from '../components/seo';
import Layout, { PageContents } from '../components/layout';
import Header from '../components/header';
import whatsapp from '../images/w1.png';
// markup
const ContactPage = () => {
  return (
    <Layout>
      <main>
        <Seo title={`Jobs ${SITE_TITLE_APPEND}`} />
        <Header />

        <PageContents title="Jobs">
          <div className="custom-text">
            <h2 className="h2 my-4">Digital Marketing Manager</h2>

            <p>
              <strong>Job Type:</strong> Contracting 25hrs/week. Mainly remote
              work/ Work from home. Will require to report to office and travel
              on duty, as required.
            </p>

            <p className="mt-4">
              <strong>Job Description:</strong> As the Digital Marketing Manager
              for Dravida Entertainments, you will be responsible for developing
              and executing marketing strategies to promote our work across all
              our verticals (Music, Distribution and Originals) increase
              audience engagement and drive revenue across all platforms
              (YouTube, Instagram, Facebook, X, LinkedIn, Spotify and Apple
              iTunes).
            </p>
            <p className="mt-4">
              You will work closely with the production team, distribution
              partners, and external agencies to ensure effective marketing
              campaigns that align with the company's objectives. You will
              report to the Managing Director.
            </p>

            <p className="mt-4">
              <strong>Responsibilities:</strong>
              <ul>
                <li>
                  Develop comprehensive marketing plans for the existing and
                  future works across our verticals, Music, Distribution and
                  Originals, including digital, social media, print, and event
                  marketing strategies.
                </li>
                <li>
                  Collaborate with the creative team to produce compelling
                  promotional materials, trailers, and posters.
                </li>
                <li>
                  Manage relationships with media outlets, influencers, and
                  industry partners to secure coverage and endorsements.
                </li>
                <li>
                  Oversee social media channels and content calendars, ensuring
                  consistent and engaging messaging.
                </li>
                <li>
                  Analyze market trends and audience insights to optimize
                  marketing efforts and identify new opportunities.
                </li>
                <li>
                  Coordinate premiere events, screenings, and promotional
                  activities to generate buzz and attract audiences.
                </li>
                <li>
                  Stay up-to-date with industry news, emerging platforms, and
                  marketing innovations to maintain a competitive edge.
                </li>
              </ul>
            </p>

            <p className="mt-4">
              <strong>Requirements:</strong>
              <ul>
                <li>
                  Bachelor's degree in Marketing, Communications or related
                  field.{' '}
                </li>
                <li>
                  Proven experience in marketing roles, preferably with a
                  production house or studio.
                </li>
                <li>
                  Strong understanding of digital marketing platforms, social
                  media trends, and audience engagement strategies.
                </li>
                <li>
                  Excellent communication and interpersonal skills, with the
                  ability to collaborate effectively with cross-functional
                  teams.
                </li>
                <li>
                  Creative thinker with a strategic mindset and problem-solving
                  abilities.
                </li>
                <li>
                  Proficiency in analytics tools and data-driven
                  decision-making. Expertise in Google AdSense.
                </li>
                <li>
                  Ability to thrive in a fast-paced, deadline-driven
                  environment.
                </li>
                <li>
                  Passion for film and storytelling, with a deep understanding
                  of audience preferences and behavior.
                </li>
                <li>
                  Flexibility to travel and work evenings/weekends as needed for
                  events and promotions.
                </li>
              </ul>
            </p>
            <p className="mt-4">
              <strong>Benefits:</strong>
              <ul>
                <li>Competitive salary</li>
                <li>Paid time off</li>
                <li>Professional development opportunities</li>
                <li>Access to screenings and industry events</li>
              </ul>
            </p>

            <p className="mt-4">
              <strong>Application Process:</strong> To apply, please submit your
              resume and cover letter outlining your relevant experience and why
              you are passionate about marketing in the creative industry.
              Additionally, include any portfolio samples or case studies
              demonstrating successful marketing campaigns you have led.
            </p>
            <div  className='mb-4'>
            <strong>Send details to:</strong>{" "}
                <a
                  title="Email Dravida Entertainments"
                  href="mailto:marketing@dravida.co.in"
                  className="dotted-underline"
                >
                  marketing@dravida.co.in
                </a>
            
            </div>
          </div>
        </PageContents>
      </main>
    </Layout>
  );
};

export default ContactPage;
